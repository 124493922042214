import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"space-around"}},[_c(VCol,{attrs:{"cols":"12","sm":"10","xl":"8"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Mot de passe oublié ")]),_c(VCardText,[_vm._v(" Veuillez entrer votre adresse pour réinitialiser votre mot de passe. "),_c(VForm,{ref:"forgotPassForm"},[_c(VTextField,{attrs:{"type":"email","label":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c(VCardActions,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"cols":"2"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.sendResetEmail}},[_vm._v("Envoyer")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }