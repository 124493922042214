import { render, staticRenderFns } from "./PasswordForgotten.vue?vue&type=template&id=722eaa52&scoped=true&"
import script from "./PasswordForgotten.vue?vue&type=script&lang=js&"
export * from "./PasswordForgotten.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "722eaa52",
  null
  
)

export default component.exports